//import UIkit from "uikit";
//import Icons from "uikit/dist/js/uikit-icons";
//import swupSetup from "./swup-setup.js";
//import $ from 'jquery';
//import barba from '@barba/core';

import 'bootstrap';

//import './cookie-functions.js';
import './_px-ready.js';

import "../../components/popup-searchform/jquery.autocomplete.min.js";

//import '../../components/field-date/field-date.js';
import '../../components/same-height/same-height.js';
import '../../components/main-menu/main-menu.js';
//import '../../components/image-compare/image-compare.js';
import '../../components/video/video.js';
import '../../components/form/form.js';
import '../../components/totop/totop.js';
//import '../../components/map/map.js';
//import '../../components/scrollspy/scrollspy.js';
import "../../components/popup-searchform/popup-searchform.js";
import "../../components/banner-browser-upgrade/banner-browser-upgrade.js";
import "../../components/banner-cookies/banner-cookies.js";

import "../../components/menu-mobile-2-niveaux/menu-mobile-2-niveaux.js";
//import "../../components/page-mode-switcher/page-mode-switcher.js";
import "../../components/share-with-opener/share-with-opener.js";

import '../../components/header/header.js';
import '../../components/menu-popover/menu-popover.js';
import '../../components/alert/alert.js';
import '../../components/archive-selection/archive-selection.js';
import '../../components/ListeServices/ListeServices.js';
import '../../components/GoogleMap/GoogleMap.js';
import '../../components/sticky-share/sticky-share.js';
//import '../../components/home-hero/home-hero.js';
//import '../../components/home-hero/home-hero-video.js';

//import '../components/uk-accordion/accordion.js';

// loads the Icon plugin
//UIkit.use(Icons);
// components can be called from the imported UIkit reference
//UIkit.notification('Hello world.');
window.dispatchEvent(new Event("px-ready"));

/*if (window.location.href.indexOf("wp-admin") > -1) {
}else{
  barba.init({
    prevent: ({ el }) => el.getAttribute('href').indexOf('/wp-admin') > 0 || (el.parentElement.classList && el.parentElement.classList.contains('page_item_has_children')),
    transitions: [{
      name: 'default-transition',
    }],
    views: [{
      namespace: 'home',
      beforeEnter() {
        // update the menu based on user navigation
        //menu.update();
      },
      afterEnter() {
        // refresh the parallax based on new page content
        //parallax.refresh();
        var event;
        if (typeof Event === "function") {
          event = new Event("px-ready");
        } else {
          event = document.createEvent("Event");
          event.initEvent("px-ready", true, true);
        }
        window.dispatchEvent(new Event("px-ready"));
      },
      prevent: function ({ el, event, href }) {
        //el.classList && el.classList.contains('prevent')
        var r = false;
        if (/.pdf/.test(href.toLowerCase())) {
          //console.log('is-pdf');
          r = true;
        }
        if (href.includes("/wp-admin/") || href.includes("/uploads/")) {
          //console.log('is-admin');
          r = true;
        }
        if (!r && is_barba_avoid(href)) {
          r = true;
        }
        //console.log(r);
        return r;
      },
      //timeout: 20000,
      requestError: (trigger, action, url, response) => {
        console.log("requesterror");
        console.log(trigger);
        console.log(action);
        console.log(url);
        console.log(response);
        if (action === "enter") {
          window.location.reload();
        }
      },
    }]
    //window.dispatchEvent(new Event("px-ready"));
  })
}*/

const links = document.querySelectorAll('a');
window.setTimeout(function(){
  console.log('links3')
  if(links){
    links.forEach(function(el){
      if(/.pdf/.test(el.getAttribute('href'))){
        //console.log(el.getAttribute('href'))
        el.setAttribute('target','_blank');
      }
    })
  }
},1000)

window.addEventListener('load', function () {
  if (document.body.classList.contains('search')) {
    const searchInput = document.querySelector('.search form .ep-autosuggest-container input[type=search]');
    const submitButton = document.querySelector('.search .ep-autosuggest-container + button[type=submit]');
    const searchForm = document.querySelector("#swup .container form")

    if (searchInput && submitButton) {
      submitButton.click();
      searchForm.style.display = 'none';
      searchForm.addEventListener('submit', function (e) {
        e.preventDefault();
        searchForm.style.display = 'none';
      });
    }
  }
});
