import $ from 'jquery';
window.addEventListener('px-ready',function(){
    $('.px-menu-mobile-2-niveaux .menu-item-has-children > a .return').remove();
    $('body').removeClass('is-mobile-menu-active');
    $('.px-menu-mobile-2-niveaux .menu-item-has-children > a').prepend('<div class="return"><svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 8.125L13.6913 8.125L9.66375 12.1638L11.25 13.75L18 7L11.25 0.25L9.66375 1.83625L13.6913 5.875L1.96701e-07 5.875L0 8.125Z" fill="white"/><path d="M0 8.125L13.6913 8.125L9.66375 12.1638L11.25 13.75L18 7L11.25 0.25L9.66375 1.83625L13.6913 5.875L1.96701e-07 5.875L0 8.125Z" fill="white"/></svg></div>')
    $(window).on('resize',function(){
        //console.log('resize');
        $('.px-menu-mobile-2-niveaux li').each(function(){
            //console.log('hello')
            $(this).css('max-height',$(this).find('a').outerHeight())
        })
    }).trigger('resize')
    $('body').on('click','.px-menu-mobile-2-niveaux .menu-item-has-children',function(){
        if(!$(this).hasClass('is-active')){
            $('.px-menu-mobile-2-niveaux .menu-item-has-children').removeClass('is-active')
            $('.px-menu-mobile-2-niveaux .main-menu').addClass('in-sub-menu')
            $(this).addClass('is-active');
            return false;
        }
    })
    $('body').on('click','.px-menu-mobile-2-niveaux .menu-item-has-children .return',function(){
        $('.px-menu-mobile-2-niveaux .menu-item-has-children').removeClass('is-active')
        $('.px-menu-mobile-2-niveaux .main-menu').removeClass('in-sub-menu')
        return false;
    });
    $('body').on('click','.menu-closer',function(){
        $('body').removeClass('is-mobile-menu-active');
    })
});