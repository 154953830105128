
window.addEventListener('px-ready',function(){
    const d = new Date();
    const alID = document.querySelectorAll('.Alert')[0].getAttribute('id');
    if(getCookie('alertSeen') != alID+'-'+d.getDate()+'-'+d.getMonth()){
        document.querySelectorAll('.Alert')[0].classList.remove('d-none')
    }
    document.querySelectorAll('.js-close-popup').forEach(function(el){
        el.addEventListener('click',function(e){closeAlert(e);})
    })
    document.addEventListener('keydown',function(e) {
        //console.log('Alert');
        //console.log(e);
        if (e.key === "Escape") {
            closeAlert(e);
        }
    });
})

function closeAlert(el){
    const d = new Date();
    const alID = document.querySelectorAll('.Alert')[0].getAttribute('id');
    setCookie('alertSeen',alID+'-'+d.getDate()+'-'+d.getMonth(),1);
    document.querySelectorAll('.Alert')[0].classList.add('d-none')
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function checkCookie() {
    let username = getCookie("username");
    if (username != "") {
        alert("Welcome again " + username);
    } else {
        username = prompt("Please enter your name:", "");
        if (username != "" && username != null) {
            setCookie("username", username, 365);
        }
    }
}