import $ from 'jquery';
window.addEventListener('px-ready',function(){
    /*$('.px-form').on('submit',function(e){
        //e.preventDefault();
        var pass = px_checkpass();
        if(!pass){
            return false;
        }
    })*/

    $('.px-form input[type="file"]').each(function(){
        $(this).wrap("<div class='filebox'></div>");
        $(this).parent().append("<div class='filetext'></div>");
    })
    $('.px-form .btnsubmit').on('click',function(e){
        //e.preventDefault();
        var pass = px_checkpass();
        console.log('formy check');
        if(!pass){
            return false;
        }else{
            if($('.px-form .g-recaptcha').length){
                $('.px-form .g-recaptcha').trigger('click');
            }else{
                $('.px-form').trigger('submit')
            }
        }
    })
    $('.px-form input[type="file"]').change(function () {
        let fullPath = $(this).val();
        let startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        let filename = fullPath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1);
        }
        $(this).parent().find('.filetext').html(filename);
    });
})

function px_checkpass(){
    var pass=true;
    $('.required,[required]').each(function(){
        $(this).removeClass('is-error');
        if(!$(this).val()){
            pass=false;
            $(this).addClass('is-error');
        }
        if($(this).attr('type') == 'checkbox'){
            if(!$(this).is(":checked")){
                pass=false;
                $(this).addClass('is-error');
            }
        }
        if($(this).hasClass('is-postcode')){
            var regExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;;
            if( regExp.test( $(this).val() ) ){

            }else{
                pass=false;
                $(this).addClass('is-error');
            }
        }
        if($(this).hasClass('is-tel')){
            var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            var inputtxt = $(this).val();
            if(inputtxt.match(phoneno)){

            }else{
                pass=false;
                $(this).addClass('is-error');
                console.log('break on tel check');
            }
        }
        if($(this).hasClass('is-email')){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test($(this).val())){

            }else{
                pass=false;
                $(this).addClass('is-error');
                console.log('break on email check');
            }
        }
        if($(this).hasClass('is-date')){
            if (Date.parse($(this).val())){

            }else{
                pass=false;
                $(this).addClass('is-error');
                console.log('break on date check');
            }
        }
        if($(this).hasClass('has-no-numbers')){
            if (/\d+/g.test($(this).val())){
                pass=false;
                $(this).addClass('is-error');
                console.log('break on no-numbers check');
            }else{
                
            }
        }
    })
    //return pass;
    //if(!pass){
    if($('.is-error').length != 0){
        console.log('not passed');
        $('.px-form').addClass('has-error');
        return false;
    }else{
        $('.px-form').removeClass('has-error');
        return true;
    }
}