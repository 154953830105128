import $ from 'jquery';
import { Loader } from "@googlemaps/js-api-loader"
window.addEventListener('px-ready',function(){
    var map;
    var markerCollection = Array();
    $('.GoogleMap__Element_close').on('click',function(){
        $('.GoogleMap__Element').removeClass('is-active');
    });
    $('.GoogleMap__sidebar_opener').on('click',function(){
        if($('body').attr('lock') != 1){
            $('body').attr('lock',1);
            $('.GoogleMap__SideBar').toggleClass('is-active');
            $('.GoogleMap__sidebar_opener').toggleClass('is-active');
            window.setTimeout(function(){$('body').attr('lock',0)},10);
        }
    })
    $('.js-close-search').on('click',function(){
        $('.GoogleMap__SideBar').removeClass('is-active');
    })
    if($('.GoogleMap__Map').length){
        $('.GoogleMap__Map').each(function(){
            var lat = 46;
            var lng = -73;
            var lang = $('.gmap-data').attr('data-lang');
            var key = $('.gmap-data').attr('data-key');
            //console.log(lat + ' ' + lng);
            let loader;
            try {
                loader = new Loader({
                    apiKey:key,
                    version:'weekly',
                    language: lang
                });
            } catch (error) {
                //console.log('change de config');
                location.reload();
            }
            loader.load().then(()=>{
                //console.log('map is up');
                //initMap(parseFloat(lat),parseFloat(lng),lang);
                //console.log('initmap');
                //var glat = parseFloat(lat);
                //var glng = parseFloat(lng);
                /*if(!glat || !glng){
                    glat = 45.516340;
                    glng = -73.559320;
                }*/
                //console.log(glat + ' ' + glng);
                var reclat = 0.0004;
                var reclng = 0.000;
                map = new google.maps.Map(document.getElementById($(this).attr('id')), {
                    center: {lat: lat+reclat, lng: lng+reclng},
                    zoom: 10,
                    language: lang,
                    streetViewControl:false,
                    mapTypeControl: false,
                    maxZoom:17,
                    styles: [
                        {
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [
                                {
                                    "color": "#f5f5f5"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#bdbdbd"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#757575"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#616161"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#e5e5e5"
                                }
                            ]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#eeeeee"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#c9c9c9"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#9e9e9e"
                                }
                            ]
                        }
                    ]
                });
                redoMarkers();
            })
        })
    }

    $('.js-choose-categ').on('click',function(){
        if($('body').attr('lock') != 1){
            $('body').attr('lock',1);
            if($(this).hasClass('is-active')){
                $(this).removeClass('is-active');
            }else{
                $('.js-choose-categ').removeClass('is-active')
                $(this).addClass('is-active');
            }
            window.setTimeout(function(){$('body').attr('lock',0)},10);
        }
        $('.GoogleMap__SideBar').removeClass('is-active');
        $('.GoogleMap__sidebar_opener').removeClass('is-active');
        redoMarkers();
    })
    $('.js-map-search').on('keyup',function(){
        redoMarkers();
    })
    $('body').on('resize',function(){
        redoMarkers();
    })

    function redoMarkers(){
        var bounds = new google.maps.LatLngBounds();
        markerCollection.forEach((e)=>{
            e.setMap(null);
        })
        markerCollection = Array();
        //console.log(bounds);
        if($('.GoogleMap__Element').length){
            $('.GoogleMap__Element').each(function(){
                var glat = $(this).attr('data-lat');
                var glng = $(this).attr('data-lng');
                var id = $(this).attr('data-id');
                var categ = $(this).attr('data-categ');
                var title = $(this).attr('data-name');
                var addThisElement = true;
                if($('.js-choose-categ.is-active').length){
                    if(categ != $('.js-choose-categ.is-active').attr('data-term-categ')){ 
                        addThisElement = false;
                    }
                }
                
                if($('.js-map-search').val()){
                    //console.log('---')
                    //console.log(title + ' --- '+ $('.js-map-search').val());
                    if(!title.toLowerCase().includes($('.js-map-search').val().toLowerCase())){
                        //console.log('skipped')
                        addThisElement = false;
                    }
                }

                if(addThisElement){
                    glat = parseFloat(glat.replace(',','.'));
                    glng = parseFloat(glng.replace(',','.'));
                    var markerColor = "#000";
                    if($(this).attr('data-pin-color')){
                        markerColor = $(this).attr('data-pin-color');
                    }
                    var marker = new google.maps.Marker({
                        //position: new google.maps.LatLng(glat,glng),
                        position:{ lat: glat, lng: glng},
                        lat:glat,
                        lng:glng,
                        icon: {
                            path:"M11.7,0C5.2,0,0,5.2,0,11.7c0,0,0,0,0,0c0,8.8,11.7,21.7,11.7,21.7s11.7-12.9,11.7-21.7C23.4,5.2,18.2,0,11.7,0C11.7,0,11.7,0,11.7,0z M11.7,15.9c-2.3,0-4.2-1.9-4.2-4.2s1.9-4.2,4.2-4.2s4.2,1.9,4.2,4.2C15.9,14,14,15.9,11.7,15.9z",
                            fillColor:markerColor,
                            fillOpacity: 1,
                            strokeWeight: 0,
                            anchor: new google.maps.Point(11,33),
                            scale:2
                        },
                        map: map,
                        id:id
                    });
                    marker.addListener('click',()=>{
                        //console.log(id);
                        $('.GoogleMap__Element').removeClass("is-active");
                        $('[data-id="'+id+'"]').addClass('is-active');
                    })
                    bounds.extend({ lat: glat, lng: glng});
                    marker.setMap(map);
                    markerCollection.push(marker);
                }
                //console.log(marker);
            });
        };
        //Geolocate
        let glat = 0;
        let glng = 0;
        console.log('Geolocate Try')
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                glat = position.coords.latitude;
                glng = position.coords.longitude;
                //console.log('place')
                var markerColor = "#000";
                //if($(this).attr('data-pin-color')){
                    //markerColor = $(this).attr('data-pin-color');
                //}
                var marker = new google.maps.Marker({
                    //position: new google.maps.LatLng(glat,glng),
                    position:{ lat: glat, lng: glng},
                    lat:glat,
                    lng:glng,
                    icon: {
                        fillColor: '#4285F4',
                        fillOpacity: 1,
                        path: google.maps.SymbolPath.CIRCLE,
                        scale: 8,
                        strokeColor: '#fff',
                        strokeWeight: 2,
                    },
                    map: map,
                    //id:id
                });
                marker.addListener('click',()=>{
                    //console.log(id);
                    //$('.GoogleMap__Element').removeClass("is-active");
                    //$('[data-id="'+id+'"]').addClass('is-active');
                })
                bounds.extend({ lat: glat, lng: glng});
                marker.setMap(map);
                markerCollection.push(marker);
                if(markerCollection.length > 0){
                    map.fitBounds(bounds);
                }
            });
        }else{
            console.log('Geolocalize not permitted')
        }
        if(markerCollection.length > 0){
            map.fitBounds(bounds);
        }
    }
})