import $ from 'jquery';
window.addEventListener('px-ready',function(){
    $('body').data('scrollb4',0);
    $('body').data('menuDisplace',0);
    $(window).on('scroll',function(){
        var scrollb4 = parseInt($('body').data('scrollb4'));
        var scroll = $(window).scrollTop().valueOf();
        var menuDisplace = parseInt($('body').data('menuDisplace'));
        var menuH = $('.l-header').outerHeight();
        var quickmenuH = $('.px-quickmenu').outerHeight();
        var menuMaxH = menuH + quickmenuH + 50;
        $('body').data('scrollb4',scroll)
        var newScrollLoc = scroll-scrollb4;
        menuDisplace += parseInt(newScrollLoc);
        if(menuDisplace < 0){menuDisplace = 0;}
        if(menuDisplace > menuMaxH){menuDisplace = menuMaxH;}
        $('body').data('menuDisplace',menuDisplace);
        menuDisplace -= quickmenuH;
        if(menuDisplace < 0){menuDisplace = 0;}
        /*if(scroll < menuMaxH){
            menuDisplace = 0;
        }*/
        //console.log(menuDisplace);
        $('.l-header').css('top','-'+menuDisplace+'px');
    }).trigger('scroll');

})