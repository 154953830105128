window.addEventListener('px-ready',function(){
    document.getElementsByTagName('body')[0].classList.remove('is-menu-open')
    document.getElementsByTagName('body')[0].setAttribute('menu-lock',0)
    const element = document.querySelectorAll('.MenuPopover__sections a');
    element.forEach(function(el){
        el.addEventListener('click',function(e){
            e.preventDefault();
            openSecondLevelMenu(el);
            return false;
        });
    });
    const elementCloser = document.querySelectorAll('.MenuPopover__submenu__close');
    elementCloser.forEach(function(el){
        el.addEventListener('click',function(e){
            e.preventDefault();
            closeSecondLevelMenu(el);
            return false;
        });
    });
    const subelement = document.querySelectorAll('.menu>.page_item_has_children>a');
    subelement.forEach(function(el){
        el.addEventListener('click',function(e){
            if(document.getElementsByTagName('body')[0].getAttribute('menu-lock') == 0){
                e.preventDefault();
                openThirdLevelMenu(el);
                return false;
            }
        });
    });
    const subsubelement = document.querySelectorAll('.menu>.page_item_has_children .page_item_has_children>a');
    subsubelement.forEach(function(el){
        el.addEventListener('click',function(e){
            if(document.getElementsByTagName('body')[0].getAttribute('menu-lock') == 0){
                e.preventDefault();
                openFourthLevelMenu(el);
                return false;
            }
        });
    });
})

function openSecondLevelMenu(el){
    var sectionID = el.getAttribute('data-page');
    if(document.querySelector('.MenuPopover__submenu[data-page="'+sectionID+'"]').classList.contains('d-none')){
        const element = document.querySelectorAll('.MenuPopover__submenu')
        element.forEach(function(el){
            el.classList.add('d-none');
        })
        document.querySelector('.MenuPopover__submenu[data-page="'+sectionID+'"]').classList.remove('d-none');
        document.querySelectorAll('.MenuPopover')[0].classList.add('is-submenu-open');
        return false;
    }
    return false;
}

function closeSecondLevelMenu(el){
    const element = document.querySelectorAll('.MenuPopover__submenu')
    element.forEach(function(el){
        el.classList.add('d-none');
    })
    document.querySelectorAll('.MenuPopover')[0].classList.remove('is-submenu-open');
}

function openThirdLevelMenu(el){
    console.log('third');
    document.getElementsByTagName('body')[0].setAttribute('menu-lock',1)
    setTimeout(function(){
        console.log('timeout2')
        document.getElementsByTagName('body')[0].setAttribute('menu-lock',0)
    },100)
    if(!el.parentElement.classList.contains('is-active')){
        const element = el.parentElement.parentElement.querySelectorAll('.page_item_has_children')
        element.forEach(function(el){
            el.classList.remove('is-active');
        })
        el.parentElement.classList.add('is-active');
        return false;
    }else{
        window.location.href = el.getAttribute('href');
    }
}

function openFourthLevelMenu(el){
    //console.log(el);
    document.getElementsByTagName('body')[0].setAttribute('menu-lock',1)
    setTimeout(function(){
        console.log('timeout')
        document.getElementsByTagName('body')[0].setAttribute('menu-lock',0)
    },100)
    console.log('fourth');
    if(!el.parentElement.classList.contains('is-active')){
        const element = el.parentElement.parentElement.querySelectorAll('.page_item_has_children')
        element.forEach(function(el){
            el.classList.remove('is-active');
        })
        el.parentElement.classList.add('is-active');
        return false;
    }else{
        window.location.href = el.getAttribute('href');
    }
}