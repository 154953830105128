import $ from 'jquery';
window.addEventListener('px-ready',function(){
    if($('.px-banner-browser-upgrade:not(.is-removed)').length){
        $('body').css('overflow','hidden')
    }
    $('.px-banner-browser-upgrade .px-button, .px-banner-browser-upgrade .close').on('click',function(){
        $('.px-banner-browser-upgrade').addClass('is-removed')
        $('body').attr('style','');
        var name="browser-bar-seen";
        var value=$('.px-banner-browser-upgrade').attr('data-current-date');
        document.cookie = name+"="+value+"; path=/";
    })
})