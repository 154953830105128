window.addEventListener('px-ready',function(){
    window.addEventListener('resize',redoListServicesHeight);
    window.addEventListener('scroll',redoListServicesHeight);
    redoListServicesHeight();
})

function redoListServicesHeight(){
    //console.log('listservices redo')
    document.querySelectorAll('.ListeServices__categ').forEach(function(el){
        resizeGridItem(el);
    })
}

function resizeGridItem(item){
    grid = document.querySelectorAll(".ListeServices__Masonry")[0];
    rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
    rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
    rowSpan = Math.ceil((item.querySelector('.ListeServices__categ_inner').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
    item.style.gridRowEnd = "span "+(rowSpan + 32);
 }