import $ from 'jquery';
window.addEventListener('px-ready',function(){
    $('.px-video').each(function(){
        var video = $(this).attr("data-loaded-video");
        if(!$(this).find('.px-malleable-image img').attr('src')){
            if(video.includes('youtube') || video.includes('yt')){
                console.log('youtube no thumb')
                var videoId = yt_get_id(video)
                $(this).find('.px-malleable-image img').attr('src','//img.youtube.com/vi/'+videoId+'/maxresdefault.jpg')
            }else if(video.includes('vimeo')){
                var videoId = vimeo_get_id(video);
                $(this).attr('vimeo-video',videoId)
                $.ajax({
                    type: "GET",
                    url: "//vimeo.com/api/v2/video/"+videoId+".xml",
                    dataType: "xml",
                    success: xmlParserVimeo
                });
                //$(this).find('.px-malleable-image img').attr('src','//i.vimeocdn.com/video/'+videoId+'.jpg')
            }
        }
    })
    $('.px-video').click(function(){
        var video = $(this).attr("data-loaded-video");
        if(video){
            console.log(video);
            if(video.includes('youtube') || video.includes('yt')){
                var videoId = yt_get_id(video)
                $(this).addClass('is-video-loaded');
                $(this).html('<iframe src="//www.youtube.com/embed/' + videoId + '?autoplay=1" px-size-ratio="16:9" allow="autoplay; fullscreen" frameborder="0" allowfullscreen></iframe>');
            }else if(video.includes('vimeo')){
                var videoId = vimeo_get_id(video)
                $(this).addClass('is-video-loaded');
                $(this).html('<iframe src="//player.vimeo.com/video/'+videoId+'?api=1&player_id=1&api=1&player_id=2&autoplay=1" px-size-ratio="16:9" allow="autoplay; fullscreen" frameborder="0" allowfullscreen></iframe>');
            }else{
                $(this).addClass('is-video-loaded');
                $(this).html('<video controls><source src="'+video+'"></video>')
            }
            $(window).trigger('resize');
            return false;
        }
    })
})

function yt_get_id(url){
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return 'error';
    }
}

function vimeo_get_id(url){
    //var url = "http://www.vimeo.com/7058755";
    var regExp = /https?:\/\/(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/;

    var match = url.match(regExp);

    if (match){
        return match[3];
    }
    else{
        alert("not a vimeo url");
    }
}

function xmlParserVimeo(xml){
    console.log('xmlparse');
    $("[vimeo-video='"+$(xml).find('id').html()+"']").find('img').attr('src',$(xml).find('thumbnail_large').html());
    $(window).trigger('resize');
}