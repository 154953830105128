//import $ from 'jquery';
window.addEventListener('px-ready',function(){
    document.querySelectorAll('.Header .Header__menu-opener').forEach(function(el){
        el.addEventListener('click',toggleHeaderMenuOpen)
    });
    document.addEventListener('keydown',function(e) {
        //console.log('Head');
        //console.log(e);
        if (e.key === "Escape") {
            document.querySelector('body').classList.remove('is-menu-open');
        }
    });
    document.querySelectorAll('.Header__OpenSearch').forEach(function(el){
        el.addEventListener('click',toggleSearchOpen)
    })
})


function toggleHeaderMenuOpen(){
    document.querySelector('body').classList.toggle('is-menu-open');
    if(document.querySelector('body').classList.contains('is-menu-open')){
        document.querySelector('body').classList.remove('is-search-open');
    }
}

function toggleSearchOpen(){
    document.querySelector('body').classList.toggle('is-search-open');
    if(document.querySelector('body').classList.contains('is-search-open')){
        document.querySelector('body').classList.remove('is-menu-open');
    }
}
