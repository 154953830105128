//import UIkit from 'uikit';
import $ from 'jquery';
window.addEventListener('px-ready',function(){
    $(window).on('resize',function(){
        redoSameH();
        redoNormalizeImages();
        window.setTimeout(function(){
            redoSameH()
            redoNormalizeImages();
        },300)
        window.setTimeout(function(){
            $('#swup').addClass('stopRedoing');
        },10000)
        $('[px-size-ratio]').each(function(){
            var attributes = $(this).attr("px-size-ratio");
            attributes = attributes.split(':');
            if($(this)[0].hasAttribute('px-size-important')){
                $(this).css('height',$(this).outerWidth()*attributes[1]/attributes[0]+'px !important');
            }else{
                $(this).css('height',$(this).outerWidth()*attributes[1]/attributes[0]);
            }
        })

    }).trigger('resize')
    //console.log('x');
    window.setTimeout(function(){
        $(window).trigger('resize');
    },500)
})
function redoSameH(){
    var categs = [];
    $('[px-same-height]').each(function(){
        if(!categs.includes($(this).attr('px-same-height'))){
            categs.push($(this).attr('px-same-height'));
        }
    })

    categs.forEach(function(element){
        var thish = 0;
        $('[px-same-height="'+element+'"]').css('height','auto');
        $('[px-same-height="'+element+'"]').each(function(){
            if($(this).outerHeight() > thish){
                thish = $(this).outerHeight();
            }
        })
        $('[px-same-height="'+element+'"]').css('height',thish+'px');
    })

    $('[px-make-square]').each(function(){
        $(this).css('min-height',$(this).outerWidth());
    })
    //console.log('x')
}

function redoNormalizeImages(){
	var hasToRedo = false;
	//console.log('redo');
	$('[px-normalize-image]:not(".loaded")').each(function(){
		var iw = $(this).width();
        var ih = $(this).height();
        var adj = $(this).attr("px-normalize-image");
        if(isNaN(adj)){
            adj = 0;
        }
        adj = adj/100;
        adj = adj+1;
        if(!iw){ iw = $(this).naturalWidth; }
        if(!ih){ iw = $(this).naturalHeight; }
        var possibleVariance = 50;
		//var loadcheck = $(this).find('img')[0].naturalHeight;
		var ratio = iw/ih;
        //console.log(loadcheck);
        //console.log(ih);
		if(ih!=0){
			if(ratio>1){
				ratio = ih/iw;
            }
            var reverseVariance = 100-possibleVariance;
			ratio = ratio * -1;
			ratio = ratio + 1;
            ratio = ratio*possibleVariance+reverseVariance;
            ratio = ratio*1.00*adj;
            var maxWPx = iw*ratio/100
            $(this).css('width',ratio*1.00 + '%');
			$(this).css('max-width',maxWPx + 'px');
            $(this).addClass('loaded');
            //console.log(ratio);
		}else{
			hasToRedo = true;
		}
	})
	if(hasToRedo){
        if(!$('#swup').hasClass('stopRedoing')){
            window.setTimeout(function(){redoNormalizeImages()},100)
        }
	}
}