window.addEventListener('px-ready',function(){
    document.querySelectorAll('.ArchiveSelection select').forEach(function(el){
        el.addEventListener('change',function(e){navigateToArchive(el);})
    })
})

function navigateToArchive(el){
    //console.log(el);
    //console.log(el.options[el.selectedIndex].getAttribute('value'));
    window.location.href = el.options[el.selectedIndex].getAttribute('value');
}