import $ from "jquery";
window.addEventListener("px-ready", function () {
  if ($(".px-banner-cookies:not(.is-removed)").length) {
    $("body").css("overflow", "hidden");
  }
  $(".px-banner-cookies .btn").on("click", function () {
    $(".px-banner-cookies").addClass("is-removed");
    $("body").attr("style", "");
    var name = "cookie-bar-seen";
    var value = $(".px-banner-cookies").attr("data-current-date");
    document.cookie = name + "=" + value + "; path=/";
  });
});
