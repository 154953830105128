//import UIkit from 'uikit';
import $ from 'jquery';
window.addEventListener('px-ready',function(){
    jQuery('body').on('click','.js-share-opener',function(){
        //Hack because code was running twice on click, for some reason
        if(!window.running){
            window.running = true;
            window.setTimeout(function(){
                jQuery('.px-share-with-opener').toggleClass('is-open');
                window.running = false;
            },100)
        }
        return false;
    })
})