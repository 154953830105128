//import UIkit from 'uikit';
import $ from "jquery";

window.addEventListener("px-ready", function () {
  var $ = jQuery;
  $(".js-open-searchform").click(function () {
    $("body").addClass("is-searchform-shown");
    $("body").addClass("is-overlay-visible");
    jQuery("html, body").animate({ scrollTop: 0 }, 200);
    $('.popin-searchform-head input[name="s"]').focus();
  });
  $(".px-popup-searchform .close")
    .click(function () {
      $("body").removeClass("is-searchform-shown");
      $("body").removeClass("is-overlay-visible");
    })
    .trigger("click");

  $(".px-popup-searchform").on("keydown", "input", function () {
    var inputVar = $(this);
    window.setTimeout(function () {
      inputVar.parent().attr("data-space", inputVar.val());
    }, 50);
  });
  $(".px-popup-searchform input").trigger("keydown");

  var searchRequest,
    urls = [],
    matches = [];
  $(window)
    .on("scroll", function () {
      jQuery(".search-autocomplete").autoComplete("update");
    })
    .trigger("scroll");
  jQuery(".search-autocomplete")
    .autoComplete({
      minChars: 2,
      onSelect: function (e, term) {
        window.location = urls[matches.indexOf(term)];
      },
      source: function (term, suggest) {
        try {
          searchRequest.abort();
        } catch (e) {}
        searchRequest = $.get(autocompleteurl, {}, function (res) {
          var len = res.length,
            regex = new RegExp(term, "gi");
          urls = [];
          matches = [];

          for (var i = 0; i < len; i++) {
            if (res[i].title.match(regex)) {
              matches.push(res[i].title);
              urls.push(res[i].url);
            }
          }
          console.log(matches);
          px_add_matches(matches, term, urls);
          //suggest(matches);
        });
      },
    })
    .trigger("keydown keyup change");
  jQuery(".search-autocomplete").autoComplete("update");
});

function px_add_matches(matches, term, urls) {
  var hasMatches = false;
  $(".autocomplete-suggestions>div,.autocomplete-suggestions>a").remove();
  console.log(urls);
  $.each(matches, function (index, value) {
    //console.log(value);
    var url = urls[index];
    //console.log(url);
    var px_pattern = new RegExp(escape(term), "gi");
    $(".autocomplete-suggestions").append(
      '<a href="' +
        url +
        '">' +
        value.replace(px_pattern, "<span>$&</span>") +
        "</a>"
    );
    hasMatches = true;
  });
  console.log(hasMatches);
  if (hasMatches && term) {
    $(".autocomplete-suggestions").addClass("has-matches");
    $(".px-popup-searchform").addClass("has-matches");
  } else {
    $(".autocomplete-suggestions").removeClass("has-matches");
    $(".px-popup-searchform").removeClass("has-matches");
  }
}
