import './_jquery.cycle2.js';
import './_jquery.cycle2.carousel.js';
import './_jquery.cycle2.swipe.js';

jQuery(function () {
  window.setTimeout(function () {
    jQuery("html").removeClass("is-animating");
    jQuery("html").attr("style", "");
  }, 30);
  var event;
  if (typeof Event === "function") {
    event = new Event("px-ready");
  } else {
    event = document.createEvent("Event");
    event.initEvent("px-ready", true, true);
  }
  window.dispatchEvent(event);
});

window.addEventListener("px-ready", function () {
  //window.scrollTo(0, 0);
  jQuery('.px-cycle-slideshow').cycle();
  jQuery('.px-cycle-slideshow').cycle('prev');
  jQuery('.px-cycle-slideshow').cycle('next');
  jQuery(document).on("click", 'a[href^="#"]', function (event) {
    console.log("scroller");
    if (!jQuery(this).hasClass("uk-accordion-title")) {
      event.preventDefault();

      var scrolltop = 0;
      if (
        jQuery(this).attr("href") != "#" &&
        jQuery(this).attr("data-noscrollup") != "1" &&
        jQuery(jQuery(this).attr("href")).length
      ) {
        scrolltop = jQuery(jQuery(this).attr("href")).offset().top;
      }

      jQuery("html, body").animate(
        {
          scrollTop: scrolltop,
        },
        350
      );
    }
  });
});
