document.querySelectorAll('.StickyShare__sharebox .accessibility .trigger').forEach((el)=>{
    el.addEventListener('click',accessibilityToggle);
})

document.querySelectorAll('.StickyShare__sharebox .accessibility .minus').forEach((el)=>{
    el.addEventListener('click',()=>{accessibilityFontSize('minus')});
})
document.querySelectorAll('.StickyShare__sharebox .accessibility .plus').forEach((el)=>{
    el.addEventListener('click',()=>{accessibilityFontSize('plus')});
})
document.querySelectorAll('.StickyShare__sharebox .accessibility .reset').forEach((el)=>{
    el.addEventListener('click',()=>{accessibilityFontSize('reset')});
})

accessibilityFontSize();

function accessibilityToggle(){
    document.querySelectorAll('.StickyShare__sharebox .accessibility')[0].classList.toggle('is-open')
}

function accessibilityFontSize(todo){
    console.log('x');
    let size = localStorage.getItem('fontSize') ? parseInt(localStorage.getItem('fontSize')) : 16;
    
    if(todo){
        if(todo == 'reset'){
            size = 16;
        }else if(todo == 'minus'){
            size = size - 2;
        }else if(todo == 'plus'){
            size = size + 2;
        }
    }
    if(size > 40){
        size = 40;
    }else if(size < 8){
        size = 8;
    }
    document.getElementsByTagName('html')[0].style.fontSize = size+'px';
    localStorage.setItem('fontSize',size);
}